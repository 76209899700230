import { onMounted, type Ref, ref } from 'vue';

export function useIsMounted() {
    const isMounted = ref(false);

    onMounted(() => {
        isMounted.value = true;
    });

    return isMounted as Readonly<Ref<boolean>>;
}
